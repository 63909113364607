import React from "react"
import { Link, navigate } from 'gatsby'
import Seo from "../components/seo"
import { sessionKeys, errorMessageTemplates } from "../services/const"
import { isLoggedIn, getUser } from "../services/auth"
import { getFormValues, setFormValues } from '../services/form'
import "../common/css/form.css"
import $ from "jquery"
import { getValue } from '../utils/check'

const displayLabels = {
  LastNameKanji: { Label: "お名前", Property: "NameKanji" },
  FirstNameKanji: { Label: "お名前", Property: "NameKanji" },
  LastNameKatakana: { Label: "お名前（フリガナ）", Property: "NameKatakana" },
  FirstNameKatakana: { Label: "お名前（フリガナ）", Property: "NameKatakana" },
  CompanyName: { Label: "会社名" },
  MailAddress: { Label: "メールアドレス" },
  MailAddressConfirmation: { Label: "メールアドレス" },
  InquiryDetail: { Label: "お問い合わせ内容" }
}

class ContactPage extends React.Component {
  state = {
    InquiryType: "協会活動全般に関すること",
    MemberNumber: "-",
    LastNameKanji: "",
    FirstNameKanji: "",
    LastNameKatakana: "",
    FirstNameKatakana: "",
    CompanyName: "",
    DeptName: "",
    TelephoneNumber: "",
    MailAddress: "",
    MailAddressConfirmation: "",
    InquiryDetail: "",
    ErrorLabelTexts: {}
  }

  getErrorLabelTexts = (state) => {
    const errorLabelTexts = {}
    const requires = ["LastNameKanji", "FirstNameKanji", "LastNameKatakana",
      "FirstNameKatakana", "CompanyName", "MailAddress", "MailAddressConfirmation", "InquiryDetail"]
    requires.forEach(v => {
      if (state[v] === null ||
        state[v] === "" ||
        state[v] === void 0 ||
        state[v].match(/^[ 　\r\n\t]*$/)) {
        errorLabelTexts[getValue(displayLabels[v].Property, v)] = displayLabels[v].Label + errorMessageTemplates.noInput
      } else if (v === "MailAddress" || v === "MailAddressConfirmation") {
        if (!state[v].match(/^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}.[A-Za-z0-9]{1,}$/)) {
          errorLabelTexts[getValue(displayLabels[v].Property, v)] = displayLabels[v].Label + errorMessageTemplates.typo
        }
      }

      if (state["MailAddress"] !== state["MailAddressConfirmation"]) {
        errorLabelTexts["MailAddressInconsistency"] = displayLabels.MailAddressConfirmation.Label + errorMessageTemplates.inconsistency
      }
    })
    return errorLabelTexts
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value,
    })
  }

  handleOnBlur = event => {
  }

  componentDidMount = () => {
    $("#agree_policy").on("click", function () {
      if ($(this).prop("checked") === true) {
        $("#form_submit").prop("disabled", false);
      } else {
        $("#form_submit").prop("disabled", true);
      }
    });

    if (isLoggedIn()) {
      this.setState({
        MemberNumber: getUser().MemberNo,
      })
    }

    const restoreValue = getFormValues(sessionKeys.contactForm, "ErrorLabelTexts")
    Object.keys(restoreValue).forEach(k => {
      this.setState({
        [k]: restoreValue[k],
      })
    })
  }

  handleContact = event => {
    event.preventDefault()

    const errorMessages = this.getErrorLabelTexts(this.state)
    if (Object.keys(errorMessages).length > 0) {
      this.setState({
        ErrorLabelTexts: errorMessages
      })
      navigate('#')
    }
    else {
      setFormValues(sessionKeys.contactForm, this.state)
      navigate('/contactConfirm')
    }
  }

  render = (data) => {
    return (
      <>
        <Seo title="お問い合わせ" />
        <div id="pageTitle">
          <h1>
            お問い合わせ
          </h1>
        </div>
        <div className="breadClumb">
          <ul>
            <li><Link to="/">HOME</Link></li>
            <li>お問い合わせ</li>
          </ul>
        </div>
        <div id="main">

          <section className="contents_block">
            <p>当協会へのご意見、ご要望、お問い合わせは下記のフォームより受付しています。皆様からの貴重なご意見をお待ちしています。<br />
              （*は入力必須項目です。）</p>
          </section>


          <section className="contents_block narrow mb50">
            <form name="" action="" onSubmit={this.handleContact}>
              <div className="form_block">
                <div className="form_head">
                  <span className="form_require">お問い合わせの種類</span>
                </div>
                <div className="form_body">
                  <label className="select-group" id="InquiryTypeLabel">
                    <select name="InquiryType" defaultValue="協会活動全般に関すること" value={this.state.InquiryType} onChange={this.handleInputChange} onBlur={this.handleOnBlur} className="select">
                      <option value="協会活動全般に関すること">協会活動全般に関すること</option>
                      <option value="ホームページに関すること">ホームページに関すること</option>
                      <option value="会員に関すること">会員に関すること</option>
                      <option value="協会誌に関すること">協会誌に関すること</option>
                      <option value="保安講習会に関すること">保安講習会に関すること</option>
                    </select>
                  </label>
                </div>
              </div>

              <div className="form_block">
                <div className="form_head">
                  <span className="form_require">お名前</span>
                </div>
                <div className="form_body">
                  <div className="form_body_parent">
                    <div className="form_body_child">
                      姓<input type="text" className="" name="LastNameKanji" value={this.state.LastNameKanji} onChange={this.handleInputChange} />
                    </div>
                    <div className="form_body_child">
                      名<input type="text" className="" name="FirstNameKanji" value={this.state.FirstNameKanji} onChange={this.handleInputChange} />
                    </div>
                  </div>
                  <div className="err"> {this.state.ErrorLabelTexts.NameKanji} </div>
                </div>
              </div>

              <div className="form_block">
                <div className="form_head">
                  <span className="form_require">お名前（カナ）</span>
                </div>
                <div className="form_body">
                  <div className="form_body_parent">
                    <div className="form_body_child">
                      姓（カナ）<input type="text" name="LastNameKatakana" value={this.state.LastNameKatakana} onChange={this.handleInputChange} className="" />
                    </div>
                    <div className="form_body_child">
                      名（カナ）<input type="text" name="FirstNameKatakana" value={this.state.FirstNameKatakana} onChange={this.handleInputChange} className="" />
                    </div>
                  </div>
                  <div className="err"> {this.state.ErrorLabelTexts.NameKatakana} </div>
                </div>
              </div>

              <div className="form_block">
                <div className="form_head">
                  <span className="form_require">会社名</span>
                </div>
                <div className="form_body">
                  <input type="text" name="CompanyName" value={this.state.CompanyName} onChange={this.handleInputChange} className="" />
                  <div className="err"> {this.state.ErrorLabelTexts.CompanyName} </div>
                </div>
              </div>

              <div className="form_block">
                <div className="form_head">
                  部署名
                </div>
                <div className="form_body">
                  <input type="text" name="DeptName" value={this.state.DeptName} onChange={this.handleInputChange} className="" />
                </div>
              </div>

              <div className="form_block">
                <div className="form_head">
                  お電話番号
                </div>
                <div className="form_body">
                  <input type="text" name="TelephoneNumber" value={this.state.TelephoneNumber} onChange={this.handleInputChange} className="" />
                </div>
              </div>

              <div className="form_block">
                <div className="form_head">
                  <span className="form_require">メールアドレス</span>
                </div>
                <div className="form_body">
                  <input type="text" name="MailAddress" value={this.state.MailAddress} onChange={this.handleInputChange} className="" />
                  <div className="err"> {this.state.ErrorLabelTexts.MailAddress} </div>
                </div>
              </div>

              <div className="form_block">
                <div className="form_head">
                  <span className="form_require">メールアドレス（確認）</span>
                </div>
                <div className="form_body">
                  <input type="text" className="" name="MailAddressConfirmation" value={this.state.MailAddressConfirmation} onChange={this.handleInputChange} />
                  <div className="err"> {this.state.ErrorLabelTexts.MailAddressConfirmation} </div>
                  <div className="err"> {this.state.ErrorLabelTexts.MailAddressInconsistency} </div>
                </div>
              </div>

              <div className="form_block">
                <div className="form_head">
                  <span className="form_require">お問い合わせ内容</span>
                </div>
                <div className="form_body">
                  <textarea name="InquiryDetail" value={this.state.InquiryDetail} onChange={this.handleInputChange} className="textarea_contact"></textarea>
                  <div className="err"> {this.state.ErrorLabelTexts.InquiryDetail} </div>
                </div>
              </div>

              <section className="contents_block narrow">
                <p>プライバシーポリシーに同意いただける場合は「同意する」にチェックを付け、以下の入力に進んでください。<br />
                  ※チェックがない（同意していただけない）場合は入力されても送信出来ません。</p>
                <div className="join_form_privacy">
                  <p>
                    ＜個人情報保護方針＞<br />
                    <br />
                    プライバシーポリシーに関して<br />
                    <br />
                    一般社団法人<br />
                    日本鉄道施設協会は、個人情報保護の社会的重要性を強く認識し、その適切な管理を行うことが重要な社会的責務と考え、ご利用の方が安心してご利用いただけるよう運営・管理に努めます。<br />
                    <br />
                    ■個人情報とは<br />
                    当協会が当サイトをご利用される方から提供を受ける住所、氏名、年齢、電話番号、メールアドレス等、個人を識別できる情報または個人固有の情報をいいます。<br />
                    <br />
                    ■個人情報の収集・取り扱いについて<br />
                    当協会が運営するWEBサイト内の一部のページに個人情報のご提供をお願いする場合があります。 収集した個人情報の取り扱いについては、個人情報の重要性を認識し、厳しい管理基準を設け管理を行っています。 収集された個人情報は、提供を受ける際にご了承頂いた利用範囲内での利用を目的とし、第三者への開示は原則として致しません。<br />
                    <br />
                    ■このプライバシーの考え方の改訂について<br />
                    当協会は、当サイトにおけるプライバシーの考え方を改訂することがあります。 重要な変更がある場合には、当サイト上において、分かりやすい方法でお知らせします。<br />
                    <br />
                    ■管理責任者及びお問合せ窓口について<br />
                    当協会では、当サイトにおけるお問合せ窓口を設置しております。 ご質問やご意見がございましたら、各事業所の電話等からお問い合わせください。<br />
                    <br />
                    一般社団法人 日本鉄道施設協会
                  </p>
                </div>
                <div className="aC mb30">
                  <div className="form_item"><label><input id="agree_policy" type="checkbox" className="" />同意する</label></div>
                </div>
              </section>

              <div className="aC"><input id="form_submit" type="submit" value="入力内容を確認する" className="btn_rounded" disabled /></div>
            </form>
          </section>
        </div>

      </>
    )
  }
}

export default ContactPage;